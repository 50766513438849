import { useQuery } from 'react-query';
import { CompanyPhone } from '@generated/types/graphql';
import { ReactQueryKey } from '@enums';
import { postGraphql } from '@services/api/base/graphql';
import { gql } from 'graphql-request';
import { apiErrorHandler } from '@utils';
import { selectWorkspaceId } from '@state/selectors';
import { useAppSelector } from '@hooks/store';

export const useCompanyPhoneList = () => {
  const companyId = useAppSelector(selectWorkspaceId);

  return useQuery([ReactQueryKey.CompanyPhones, companyId], async () => {
    try {
      const data = await postGraphql<{ companyPhones: CompanyPhone[] }>(
        gql`
          query COMPANY_PHONE_LIST_QUERY($companyId: Int!) {
            companyPhones(filter: { companyId: { equalTo: $companyId }, isPermanentlyDeleted: { equalTo: false } }) {
              id
              phone
              twilioSid
              companyId
              alias
              createdAt
              createdByUser {
                id
                lastName
                lastName
              }

              companyPhoneUsers {
                canSend
                canReceiveAll
                user {
                  id
                  firstName
                  lastName
                  email
                }
              }
            }
          }
        `,
        { companyId }
      );

      return data.companyPhones;
    } catch (e) {
      throw apiErrorHandler('Error fetching company phones', e);
    }
  });
};
