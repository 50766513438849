import { ReactQueryKey } from '@enums';
import { SystemLogThread, SystemLogThreadFilter, SystemLogThreadsConnection } from '@generated/types/graphql';
import { postGraphql } from '@services/api/base/graphql';
import { gql } from 'graphql-request';
import { useMemo } from 'react';
import { useInfiniteQuery } from 'react-query';
import { DeepPartial } from 'redux';

const PER_PAGE = 20;

const fetchSystemSyncLogs = async ({
  systemId,
  pageParam,
  perPage = PER_PAGE
}: {
  systemId: number;
  pageParam: number;
  perPage?: number;
}) => {
  const filter: DeepPartial<SystemLogThreadFilter> = {
    systemId: {
      equalTo: systemId
    }
  };

  const { systemLogThreadsConnection } = await postGraphql<{ systemLogThreadsConnection: SystemLogThreadsConnection }>(
    gql`
      query SYSTEM_SYNC_LOGS_QUERY($filter: SystemLogThreadFilter, $offset: Int, $first: Int) {
        systemLogThreadsConnection(filter: $filter, offset: $offset, first: $first, orderBy: CREATED_AT_DESC) {
          nodes {
            thread
            events
            createdAt
            systemId
            status
          }
          totalCount
        }
      }
    `,
    {
      filter,
      first: perPage,
      offset: pageParam * perPage
    }
  );

  return {
    logs: systemLogThreadsConnection.nodes,
    totalCount: systemLogThreadsConnection.totalCount,
    pageParam
  };
};

export type PaginatedSystemSyncLogs = {
  logs: SystemLogThread[];
  totalCount: number;
  pageParam: number;
};

export const useSystemSyncLogs = ({ systemId, perPage = PER_PAGE }: { systemId: number; perPage?: number }) => {
  const { data, fetchNextPage, isLoading, isFetching, isFetchingNextPage } = useInfiniteQuery<PaginatedSystemSyncLogs>(
    [ReactQueryKey.System, 'systemSyncLogs', systemId],
    ({ pageParam = 0 }) => fetchSystemSyncLogs({ systemId, pageParam }),
    {
      keepPreviousData: true,
      getNextPageParam: (lastPage) => {
        return lastPage.logs.length === perPage ? lastPage.pageParam + 1 : undefined;
      }
    }
  );

  const flatten = useMemo(() => {
    if (!data) {
      return [];
    }

    return data.pages.map((page) => page.logs).flat();
  }, [data]);

  return {
    logs: flatten,
    total: data?.pages[0]?.totalCount ?? 0,
    fetchNextPage,
    isLoading,
    isFetching,
    isFetchingNextPage
  };
};
