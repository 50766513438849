import { ReactQueryKey } from '@enums';
import { Project, ProjectStage } from '@generated/types/graphql';
import { postGraphql } from '@services/api/base/graphql';
import { apiErrorHandler } from '@utils/api';
import { gql } from 'graphql-request';
import { useQuery } from 'react-query';

export type RecordStageListItem = Pick<ProjectStage, 'id' | 'name'>;

export const useRecordStageList = (recordId: number) => {
  return useQuery<RecordStageListItem[]>(
    [ReactQueryKey.RecordStageList, recordId],
    async () => {
      try {
        const { project } = await postGraphql<{ project: Project }>(
          gql`
            query STAGES_BY_RECORD_ID_QUERY($recordId: Int!) {
              project(id: $recordId) {
                blueprint {
                  blueprintProjectStages(orderBy: POSITION_ASC) {
                    projectStage {
                      id
                      name
                    }
                  }
                }
              }
            }
          `,
          { recordId }
        );

        return project.blueprint?.blueprintProjectStages.map((stage) => stage.projectStage) ?? [];
      } catch (e) {
        throw apiErrorHandler('Error fetching record stage list', e);
      }
    },
    {
      staleTime: 1000 * 3600 * 24
    }
  );
};
