import { useMutation, useQuery, useQueryClient } from 'react-query';
import { ReactQueryKey } from '@enums';
import { useAppSelector } from '@hooks/store';
import { selectWorkspaceId } from '@state/selectors';
import { apiErrorHandler } from '@utils';
import projectStagesAPI from '@services/api/projectStagesAPI';
import { RecordType, WorkspaceStageDTO } from '@types';
import { errorHandler } from '@services/api/helpers';
import { postGraphql } from '@services/api/base/graphql';
import { gql } from 'graphql-request';
import { ProjectStage } from '@generated/types/graphql';

/**
 * @deprecated Make graphql version
 */
export const useProjectStagesRest = (providedCompanyId?: number) => {
  const selectedCompanyId = useAppSelector(selectWorkspaceId)!;
  const companyId = providedCompanyId || selectedCompanyId;

  const FETCH_QUERY_KEY = [ReactQueryKey.WorkspaceStages, companyId, 'rest'];

  const fetchAllStagesQuery = useQuery(FETCH_QUERY_KEY, async () => {
    try {
      const { data } = await projectStagesAPI.fetchProjectStages(companyId);

      return data;
    } catch (error) {
      throw apiErrorHandler('error fetching project stages', error);
    }
  });

  return {
    fetchAll: fetchAllStagesQuery
  };
};

export const useProjectStages = (recordType: RecordType) => {
  const companyId = useAppSelector(selectWorkspaceId);

  return useQuery(
    [ReactQueryKey.WorkspaceStages, recordType, companyId],
    async () => {
      try {
        return (
          await postGraphql<{ projectStages: ProjectStage[] }>(
            gql`
              query STAGES_BY_RECORD_TYPE_QUERY($filter: ProjectStageFilter!) {
                projectStages(filter: $filter, orderBy: [CREATED_AT_ASC]) {
                  id
                  companyId
                  name
                  type
                  createdAt
                  yellowSla
                  redSla
                  scope
                }
              }
            `,
            {
              filter: {
                companyId: { equalTo: companyId },
                scope: { equalTo: recordType }
              }
            }
          )
        ).projectStages;
      } catch (error) {
        throw apiErrorHandler('error fetching project stages', error);
      }
    },
    {
      enabled: !!companyId && !!recordType,
      refetchOnMount: false,
      staleTime: 1000 * 3600 * 24
    }
  );
};

export const useProjectStageMutations = (companyId: number) => {
  const queryClient = useQueryClient();

  const createStageMutation = useMutation<void, Error, WorkspaceStageDTO>(
    async (dto: WorkspaceStageDTO) => {
      try {
        await projectStagesAPI.createProjectStage(companyId, dto);
      } catch (error) {
        throw errorHandler(error);
      }
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(ReactQueryKey.WorkspaceStages);
        queryClient.invalidateQueries(ReactQueryKey.WorkspaceStagesGql);
      }
    }
  );

  const updateStageMutation = useMutation<void, Error, { stageId: number; dto: WorkspaceStageDTO }>(
    async ({ stageId, dto }) => {
      try {
        await projectStagesAPI.updateProjectStage(companyId, stageId, dto);
      } catch (error) {
        throw errorHandler(error);
      }
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(ReactQueryKey.WorkspaceStages);
        queryClient.invalidateQueries(ReactQueryKey.WorkspaceStagesGql);
      }
    }
  );

  const deleteStageMutation = useMutation<void, Error, number>(
    async (stageId) => {
      try {
        await projectStagesAPI.deleteProjectStage(companyId, stageId);
      } catch (error) {
        throw errorHandler(error);
      }
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(ReactQueryKey.WorkspaceStages);
        queryClient.invalidateQueries(ReactQueryKey.WorkspaceStagesGql);
      }
    }
  );

  return {
    create: createStageMutation,
    update: updateStageMutation,
    delete: deleteStageMutation
  };
};
