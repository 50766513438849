import React, { useEffect } from 'react';
import { ReactQueryKey } from '@enums';
import { useQuery } from 'react-query';
import { CircularProgress } from '@material-ui/core';
import { colors } from '@styles';
import { File } from '@generated/types/graphql';
import { postGraphql } from '@services/api/base/graphql';
import { gql } from 'graphql-request';
import { Spinner, ThumbnailContainer } from './styled';

export const fetchFile = async (id: number) => {
  const { file } = await postGraphql<{ file: File }>(
    gql`
      query FORM_FILE_FIELD_FILE_QUERY($id: Int!) {
        file(id: $id) {
          id
          name
          type
          metaData
          annotations
          downloadUrl
          projectId
          createdByUser {
            id
            firstName
            lastName
            email
            avatarUrl
          }
        }
      }
    `,
    { id }
  );

  return file;
};

const useFile = (id: number) => {
  return useQuery([ReactQueryKey.PropertyFileById, id], () => fetchFile(id));
};

interface Props {
  id: number;
  onLoaded: (file: File) => void;
}

export const FileToLoad = ({ id, onLoaded }: Props) => {
  const { data: file } = useFile(id);

  useEffect(() => {
    if (file) {
      onLoaded(file);
    }
  }, [file, onLoaded]);

  return (
    <ThumbnailContainer>
      <Spinner>
        <CircularProgress size={16} style={{ color: colors.green }} />
      </Spinner>
    </ThumbnailContainer>
  );
};
