import { useCompanyProperties, useCompanyPropertiesWithoutRouter } from '@hooks/useCompanyProperties';
import { useQuery, useQueryClient, useMutation } from 'react-query';
import { keyBy, find, identity } from 'lodash/fp';
import { ReactQueryKey } from '@enums';
import { RecordType, VerboseType, Identified, Property } from '@types';
import { ColumnGroupsConnection, ColumnToGroupsConnection } from '@generated/types/graphql';
import { postGraphql } from '@services/api/base/graphql';
import { gql } from 'graphql-request';
import { useMemo } from 'react';
import { useToast } from '@hooks/useToast';
import api from '@services/api/propertyGroupApi';
import { errorHandler } from '@services/api/helpers';
import {
  PropertyGroupCreateDto,
  PropertyGroupUpdateDto,
  PropertyGroupLayoutDto,
  PropertyLayoutDto
} from '@services/api/types';
import { isDefault } from '@utils/properties';
import { selectWorkspaceId } from '@state/selectors';
import { useAppSelector } from './store';

export type PropertyGroup = VerboseType<
  Pick<ColumnGroupsConnection['nodes'][number], 'id' | 'name' | 'stage' | 'position'> & {
    properties: ReturnType<typeof useCompanyProperties>['allProperties'];
  }
>;

export const DEFAULT_GROUP_ID = 0;
const NONE_GROUP_ID = Infinity;

export const isDefaultPropertiesGroup = (propertyGroup: Identified<Property['id']>) =>
  propertyGroup && propertyGroup.id === DEFAULT_GROUP_ID;
export const isNonePropertiesGroup = (propertyGroup: Identified<Property['id']>) =>
  propertyGroup && propertyGroup.id === NONE_GROUP_ID;

export type UsePropertyGroupsProps = {
  scope: RecordType;
  fullAccess?: boolean;
};

export const usePropertyGroups = ({ scope, fullAccess }: UsePropertyGroupsProps) => {
  const companyId = useAppSelector(selectWorkspaceId);
  const {
    allProperties = [],
    scopeToAllColumns,
    scopeToColumns
  } = useCompanyPropertiesWithoutRouter(companyId, { recordType: scope, fullAccess });
  const idToProperty = useMemo(() => keyBy('id', allProperties), [allProperties]);

  const filterFunction = (
    fullAccess
      ? identity
      : (values: Property[]) =>
          (values || []).filter(({ id, columnId }) => {
            const $id = columnId || id;

            return !!find({ id: $id }, scopeToColumns[scope]);
          })
  ) as (values: Property[]) => Property[];

  return useQuery<PropertyGroup[]>(
    [ReactQueryKey.PropertyGroups, scope, idToProperty, companyId],
    async () => {
      const { groups, noneGroup } = await postGraphql<{
        groups: ColumnGroupsConnection;
        noneGroup: ColumnToGroupsConnection;
      }>(
        gql`
          query PROPERTY_GROUPS_QUERY($scope: String!, $companyId: Int!) {
            groups: columnGroupsConnection(
              filter: { companyId: { equalTo: $companyId }, scope: { equalTo: $scope } }
              orderBy: [POSITION_ASC]
            ) {
              nodes {
                id
                name
                stage {
                  id
                  name
                }
                position
                columnToGroupsByGroupId(orderBy: [POSITION_ASC]) {
                  position
                  columnId
                }
              }
            }
            noneGroup: columnToGroupsConnection(
              filter: { companyId: { equalTo: $companyId }, groupExists: false, scope: { equalTo: $scope } }
              orderBy: [POSITION_ASC]
            ) {
              nodes {
                position
                columnId
              }
            }
          }
        `,
        {
          scope,
          companyId
        }
      );

      const itemsWithGrouping = groups.nodes.map(({ columnToGroupsByGroupId, ...group }) => {
        return {
          ...group,
          properties: filterFunction(columnToGroupsByGroupId).map((property) => ({
            ...idToProperty[property.columnId],
            ...property
          }))
        };
      });

      return [
        {
          id: DEFAULT_GROUP_ID,
          name: 'Standard',
          position: 0,
          properties: [...scopeToAllColumns[scope].filter(isDefault)]
        },
        ...itemsWithGrouping,
        {
          id: NONE_GROUP_ID,
          name: 'Other',
          position: itemsWithGrouping.length + 1,
          properties: [
            // ...scopeToAllColumns[scope].filter(({ virtual, isAdditional }) => !virtual && !isAdditional),
            ...filterFunction(noneGroup.nodes).map((property) => ({
              ...idToProperty[property.columnId],
              ...property
            }))
          ]
        }
      ] as PropertyGroup[];
    },
    {
      enabled: !!allProperties.length,
      initialData: [],
      keepPreviousData: true
    }
  );
};

export const usePropertyGroupMutation = () => {
  const { showError, showSuccess } = useToast();
  const companyId = useAppSelector(selectWorkspaceId);
  const queryClient = useQueryClient();

  const update = useMutation<void, Error, { groupId: number; dto: PropertyGroupUpdateDto }>(
    async ({ groupId, dto }) => {
      try {
        await api.update(companyId, groupId, dto);
      } catch (error) {
        throw errorHandler(error);
      }
    },
    {
      onError: (error) => {
        showError(error.message);
      },
      onSuccess: async () => {
        showSuccess('Successfully updated the group');
        await queryClient.invalidateQueries(ReactQueryKey.PropertyGroups);
      }
    }
  );

  const remove = useMutation<void, Error, { groupId: number }>(
    async ({ groupId }) => {
      try {
        await api.remove(companyId, groupId);
      } catch (error) {
        throw errorHandler(error);
      }
    },
    {
      onError: (error) => {
        showError(error.message);
      },
      onSuccess: async () => {
        showSuccess('Successfully removed the group');
        await queryClient.invalidateQueries(ReactQueryKey.PropertyGroups);
      }
    }
  );

  // group position will be after last group's position + 1
  const create = useMutation<PropertyGroup, Error, { dto: PropertyGroupCreateDto }>(
    async ({ dto }) => {
      try {
        return (await api.create(companyId, dto)).data as unknown as PropertyGroup;
      } catch (error) {
        throw errorHandler(error);
      }
    },
    {
      onError: (error) => {
        showError(error.message);
      },
      onSuccess: async () => {
        showSuccess('Successfully created the group');
        await queryClient.invalidateQueries(ReactQueryKey.PropertyGroups);
      }
    }
  );

  /**
   * Use it when changing ordering of a groups inside scope(ACCOUNT/DEAL/PROJECT).
   * Positions for groups will be calculated by index in the array
   */
  const updateLayout = useMutation<void, Error, { dto: PropertyGroupLayoutDto }>(
    async ({ dto }) => {
      try {
        await api.updateLayout(companyId, dto);
      } catch (error) {
        throw errorHandler(error);
      }
    },
    {
      onError: (error) => {
        showError(error.message);
      },
      onSuccess: async () => {
        showSuccess('Successfully updated the group layout');
        await queryClient.invalidateQueries(ReactQueryKey.PropertyGroups);
      }
    }
  );

  /**
   * Use it when changing property ordering of a group.
   * If property is moved from one group to another send both groups property order.
   * Positions for properties will be calculated by index in the array
   */
  const updatePropertyLayout = useMutation<void, Error, { dto: PropertyLayoutDto[] }>(
    async ({ dto }) => {
      try {
        await api.updateColumnLayout(companyId, dto);
      } catch (error) {
        throw errorHandler(error);
      }
    },
    {
      onError: (error) => {
        showError(error.message);
      },
      onSuccess: async () => {
        showSuccess('Successfully updated the property layout');
        await queryClient.invalidateQueries(ReactQueryKey.PropertyGroups);
      }
    }
  );

  return {
    update,
    create,
    remove,
    updateLayout,
    updatePropertyLayout
  };
};
