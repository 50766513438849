import { PrivilegedTask, Project } from '@generated/types/graphql';
import { useAppSelector } from '@hooks/store';
import { useBlueprint } from '@hooks/useBlueprints';
import { postGraphql } from '@services/api/base/graphql';
import { selectWorkspaceId } from '@state/selectors';
import { apiErrorHandler } from '@utils/api';
import { gql } from 'graphql-request';
import { useMemo } from 'react';
import { useQuery } from 'react-query';

type ProjectTasksShort = Pick<PrivilegedTask, 'id' | 'uid' | 'title' | 'templateTaskId'>;

const useProjectTasks = (projectId?: number) =>
  useQuery<ProjectTasksShort[]>(
    ['project-tasks-for-create-check', projectId],
    async () => {
      try {
        return (
          await postGraphql<{ project: Project }>(
            gql`
              query CHECK_PROJECT_TASKS_QUERY($projectId: Int!) {
                project(id: $projectId) {
                  privilegedTasksByProjectIdConnection {
                    nodes {
                      id
                      uid
                      title
                      templateTaskId
                    }
                  }
                }
              }
            `,
            { projectId }
          )
        ).project.privilegedTasksByProjectIdConnection.nodes;
      } catch (e) {
        throw apiErrorHandler('error fetching project tasks', e);
      }
    },
    {
      staleTime: 0,
      enabled: Boolean(projectId)
    }
  );

export const useTaskCreatingChecks = (
  project?: { id: number; blueprintId: number | null; stage: { id: number } | null },
  template?: { id: number }
) => {
  const companyId = useAppSelector(selectWorkspaceId);

  const { data: blueprint } = useBlueprint(companyId, project?.blueprintId);

  const { data: tasks, isLoading: isTasksLoading } = useProjectTasks(project?.id);

  const existingsTasks = useMemo(() => {
    if (!tasks?.length || !template) {
      return [];
    }

    return tasks.filter((task) => task.templateTaskId === template.id);
  }, [tasks, template]);

  const plannedStageForTemplate = useMemo(() => {
    if (!blueprint || !template || !project?.stage) {
      return null;
    }

    return blueprint.blueprintProjectStages.find((projectStage) =>
      projectStage.blueprintTasks.some((blueprintTask) => blueprintTask.taskId === template.id)
    )?.projectStage;
  }, [blueprint, template, project?.stage]);

  const taskWillBeCreatedInTheFuture = useMemo(() => {
    if (!blueprint || !template || !project.stage) {
      return false;
    }

    if (!plannedStageForTemplate) {
      return false;
    }

    if (existingsTasks.length || isTasksLoading) {
      return false;
    }

    const currentStagePosition = blueprint.blueprintProjectStages.findIndex(
      (stage) => project.stage.id === stage.projectStage.id
    );
    const plannedStagePosition = blueprint.blueprintProjectStages.findIndex(
      (stage) => plannedStageForTemplate.id === stage.projectStage.id
    );

    return plannedStagePosition > currentStagePosition;
  }, [blueprint, template, plannedStageForTemplate, project, existingsTasks, isTasksLoading]);

  if (!project || !template) {
    return null;
  }

  return {
    existingsTasks,
    notBelongsToWorkflow: Boolean(project.stage && blueprint && !plannedStageForTemplate),
    taskWillBeCreatedInTheFuture,
    plannedStageForTemplate
  };
};
