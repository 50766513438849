import { ReactQueryKey } from '@enums';
import { Action, ActionsConnection } from '@generated/types/graphql';
import { postGraphql } from '@services/api/base/graphql';
import { selectWorkspaceId } from '@state/selectors';
import { apiErrorHandler } from '@utils/api';
import { gql } from 'graphql-request';
import { useQuery } from 'react-query';
import { useSelector } from 'react-redux';

export type ActionTemplateListItem = Pick<
  Action,
  'id' | 'title' | 'description' | 'assignAllContacts' | 'type' | 'createdByUser'
>;

export const useActionTemplateList = () => {
  const companyId = useSelector(selectWorkspaceId);

  return useQuery<ActionTemplateListItem[]>([ReactQueryKey.ActionTemplateList], async () => {
    try {
      const { actionsConnection } = await postGraphql<{ actionsConnection: ActionsConnection }>(
        gql`
          query ACTION_ITEM_TEMPLATE_LIST_QUERY($companyId: Int!) {
            actionsConnection(
              filter: { isTemplate: { equalTo: true }, companyId: { equalTo: $companyId } }
              orderBy: CREATED_AT_DESC
            ) {
              nodes {
                id
                title
                description
                type
                assignAllContacts
                createdByUser {
                  id
                  firstName
                  lastName
                  avatarUrl
                }
              }
            }
          }
        `,
        { companyId }
      );

      return actionsConnection.nodes;
    } catch (e) {
      throw apiErrorHandler('Error fetching aciton templates', e);
    }
  });
};
