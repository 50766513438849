import { ReactQueryKey } from '@enums';
import { FilterFolder } from '@generated/types/graphql';
import { postGraphql } from '@services/api/base/graphql';
import { selectWorkspaceId } from '@state/selectors';
import { apiErrorHandler } from '@utils/api';
import { gql } from 'graphql-request';
import { useQuery } from 'react-query';
import { useSelector } from 'react-redux';

export const useSmartViewFolderList = () => {
  const companyId = useSelector(selectWorkspaceId);

  return useQuery<FilterFolder[]>([ReactQueryKey.SmartViewFolderList, companyId], async () => {
    try {
      const { filterFolders } = await postGraphql<{ filterFolders: FilterFolder[] }>(
        gql`
          query SMART_VIEW_FOLDER_LIST_QUERY($companyId: Int!) {
            filterFolders(filter: { companyId: { equalTo: $companyId } }, orderBy: NAME_ASC) {
              id
              name
            }
          }
        `,
        { companyId }
      );

      return filterFolders;
    } catch (e) {
      throw apiErrorHandler('Error fetching Smart View folders', e);
    }
  });
};
