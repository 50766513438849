import { ReactQueryKey } from '@enums';
import { Action } from '@generated/types/graphql';
import { postGraphql } from '@services/api/base/graphql';
import { apiErrorHandler } from '@utils/api';
import { gql } from 'graphql-request';
import { useQuery } from 'react-query';

export const useActionItem = (id?: number) => {
  return useQuery<Action>(
    [ReactQueryKey.ActionItemList, id],
    async () => {
      try {
        const { action } = await postGraphql<{ action: Action }>(
          gql`
            query ACTION_ITEM_BY_ID_QUERY($id: Int!) {
              action(id: $id) {
                id
                title
                type
                description
                dueDate
                status
                project {
                  projectContacts {
                    id
                    name
                    portalStatus
                  }
                }
                projectStage {
                  id
                  name
                  type
                }
                actionAssignees {
                  contact {
                    id
                    name
                    portalStatus
                  }
                }
                files {
                  id
                  name
                  metaData
                }

                actionStatusUpdates {
                  id
                  status
                  createdAt
                }
              }
            }
          `,
          { id }
        );

        return action;
      } catch (e) {
        throw apiErrorHandler('Error fetching Aciton Item', e);
      }
    },
    { enabled: Boolean(id) }
  );
};
