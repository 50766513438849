import { ReactQueryKey } from '@enums';
import { Filter } from '@generated/types/graphql';
import { postGraphql } from '@services/api/base/graphql';
import { SmartViewFilter } from '@types';
import { apiErrorHandler } from '@utils/api';
import { gql } from 'graphql-request';
import { useQuery } from 'react-query';
import { selectWorkspaceId } from '@state/selectors';
import { useAppSelector, useCompanyProperties } from '..';
import { convertMultiValuesToArray, migrateOldFiltersToNew } from './helpers';

export const useSmartViewList = () => {
  const companyId = useAppSelector(selectWorkspaceId);
  const { properties } = useCompanyProperties();

  return useQuery<SmartViewFilter[]>(
    [ReactQueryKey.SmartViewList, companyId],
    async () => {
      try {
        const { filters } = await postGraphql<{ filters: Filter[] }>(
          gql`
            query SMART_VIEW_LIST_QUERY($companyId: Int!) {
              filters(filter: { companyId: { equalTo: $companyId } }, orderBy: NAME_ASC) {
                id
                name
                scope
                type
                emoji
                filterFolderId
                conditions

                sharedRoles: rolesByFilterToRoleFilterIdAndRoleIdList {
                  id
                  name
                }

                sharedTeams: teamsByFilterToTeamFilterIdAndTeamIdList {
                  id
                  name
                }

                sharedUsers: mainUsersByFilterToUserFilterIdAndUserIdList {
                  id
                  firstName
                  lastName
                  avatarUrl
                }
              }
            }
          `,
          { companyId }
        );

        return convertMultiValuesToArray(filters, properties).map(migrateOldFiltersToNew) as SmartViewFilter[];
      } catch (e) {
        throw apiErrorHandler('Error fetching smartview list', e);
      }
    },
    { enabled: Boolean(properties?.length), refetchOnMount: false }
  );
};
