import { ReactQueryKey } from '@enums';
import { Action, ActionsOrderBy } from '@generated/types/graphql';
import { postGraphql } from '@services/api/base/graphql';
import { apiErrorHandler } from '@utils/api';
import { gql } from 'graphql-request';
import { useQuery } from 'react-query';

export type ActionItemListItem = Pick<
  Action,
  'id' | 'title' | 'actionAssignees' | 'dueDate' | 'status' | 'projectStage'
>;

export const useActionItemList = (recordId: number, orderBy: ActionsOrderBy = ActionsOrderBy.CreatedAtDesc) => {
  return useQuery<ActionItemListItem[]>([ReactQueryKey.ActionItemList, recordId, orderBy], async () => {
    try {
      const { actions } = await postGraphql<{ actions: Action[] }>(
        gql`
          query ACTION_ITEMS_BY_RECORD_ID_QUERY($recordId: Int!, $orderBy: [ActionsOrderBy!]) {
            actions(filter: { projectId: { equalTo: $recordId } }, orderBy: $orderBy) {
              id
              title
              actionAssignees {
                contact {
                  id
                  name
                  portalStatus
                }
              }
              dueDate
              status
              projectStage {
                id
                name
                type
              }
            }
          }
        `,
        { recordId, orderBy }
      );

      return actions;
    } catch (e) {
      throw apiErrorHandler('Error fetching record action item list', e);
    }
  });
};
