import { useQuery, useQueryClient, useMutation } from 'react-query';
import { ReactQueryKey } from '@enums';
import { useRoutes } from '@hooks/useRoutes';
import { Paging, CatalogType, CatalogItemCreateDto, CatalogItemUpdateDto, CatalogItemFromMasterDto } from '@types';
import {
  CatalogItem as CatalogItemFromApi,
  CatalogItemFilter,
  Dictionary,
  CatalogItem
} from '@generated/types/graphql';
import { postGraphql } from '@services/api/base/graphql';
import { gql } from 'graphql-request';
import { useToast } from '@hooks/useToast';
import api from '@services/api/catalogApi';
import { errorHandler } from '@services/api/helpers';
import { apiErrorHandler } from '@utils';
import { Pagination } from '@common/Table';
import { DeepPartial } from 'redux';
import { flow, groupBy } from 'lodash/fp';

const map = require('lodash/fp/map').convert({ cap: false });

export type UseCatalogItemsProps = {
  paging: Pagination;
  searchFilter?: string;
  catalogId?: number;
  seeOnlyEnabled: boolean;
  catalogType?: CatalogType;
};

const getCatalogItemsFilter = (companyId: number, onlyEnabled = false): DeepPartial<CatalogItemFilter> => ({
  or: [
    {
      isArchived: { equalTo: false },
      companyId: { equalTo: companyId }
    },
    ...(!onlyEnabled
      ? [
          {
            isCustom: { equalTo: false },
            isArchived: { equalTo: false },
            catalogItemsByMasterItemId: {
              none: {
                companyId: { equalTo: companyId },
                isArchived: { equalTo: false }
              }
            }
          }
        ]
      : [])
  ]
});

export const isProduct = (catalog: Dictionary) => catalog?.parentCode === CatalogType.PRODUCT;
export const isMasterItem = (item: CatalogItem) => !item?.isCustom && !item?.companyId && item?.id;
// merges master item to custom item
export const catalogItemAdapter = (item: CatalogItem) => ({
  ...(item.masterItem
    ? {
        ...item.masterItem,
        id: item.id,
        price: item.price,
        cost: item.cost,
        description: item.description || item.masterItem.description,
        spec: Object.keys(item.spec).length > 0 ? item.spec : item.masterItem.description,
        image: item.image || item.masterItem.image,
        companyId: item.companyId,
        masterItem: item.masterItem
      }
    : item)
});

type GroupedCatalog = {
  parentCatalog: string;
  catalogs: Dictionary[];
};
export const useCatalog = () => {
  const { companyId } = useRoutes();

  return useQuery<GroupedCatalog[]>(
    [ReactQueryKey.Catalog, companyId],
    async () => {
      try {
        return flow(
          groupBy<Dictionary>((catalog) => catalog.parentCode),
          map((value, key) => ({
            parentCatalog: key,
            catalogs: value
          }))
        )(
          (
            await postGraphql<{ dictionaries: Dictionary[] }>(
              gql`
                query CATALOG_QUERY($filter: CatalogItemFilter) {
                  dictionaries(filter: { parentCode: { in: ["PRODUCT", "SERVICE"] } }, orderBy: [VALUE_ASC]) {
                    id
                    parentCode
                    code
                    value
                    catalogItemsByCategoryIdConnection(filter: $filter) {
                      totalCount
                    }
                  }
                }
              `,
              {
                filter: getCatalogItemsFilter(companyId, true)
              }
            )
          ).dictionaries
        ) as GroupedCatalog[];
      } catch (e) {
        throw apiErrorHandler('Error fetching catalogs', e);
      }
    },
    {
      enabled: !!companyId,
      initialData: [],
      keepPreviousData: true
    }
  );
};

export const useCatalogItems = ({
  paging = {
    perPage: 10,
    page: 1
  } as unknown as Pagination,
  catalogId,
  searchFilter = '',
  seeOnlyEnabled,
  catalogType
}: UseCatalogItemsProps) => {
  const { companyId } = useRoutes();

  const filter = getCatalogItemsFilter(companyId, seeOnlyEnabled);
  const itemFilter = {
    ...(catalogId ? { categoryId: { equalTo: catalogId } } : {}),
    ...(catalogType ? { type: { equalTo: catalogType } } : {}),
    ...(searchFilter
      ? {
          or: [
            { name: { includesInsensitive: searchFilter } },
            { manufacturer: { includesInsensitive: searchFilter } },
            { description: { includesInsensitive: searchFilter } },
            { sku: { includesInsensitive: searchFilter } }
          ]
        }
      : {})
  } as DeepPartial<CatalogItemFilter>;

  return useQuery<Paging<CatalogItem>>(
    [
      ReactQueryKey.CatalogItem,
      companyId,
      JSON.stringify(paging),
      catalogId,
      searchFilter,
      seeOnlyEnabled,
      catalogType
    ],
    async () => {
      try {
        const { items } = await postGraphql<{ items: Paging<CatalogItem> }>(
          gql`
            query CATALOG_ITEMS_QUERY($filter: CatalogItemFilter, $first: Int!, $offset: Int!) {
              items: catalogItemsConnection(
                filter: $filter
                first: $first
                offset: $offset
                orderBy: [CREATED_AT_DESC]
              ) {
                results: nodes {
                  id
                  name
                  code
                  type
                  sku
                  datasheet
                  description
                  manufacturer
                  image {
                    metaData
                    annotations
                    id
                    name
                  }
                  spec
                  price
                  cost
                  isCustom
                  companyId
                  masterItem {
                    id
                    name
                    code
                    type
                    sku
                    datasheet
                    description
                    manufacturer
                    image {
                      metaData
                      id
                      name
                    }
                    spec
                    price
                    cost
                  }
                }
                total: totalCount
              }
            }
          `,
          {
            filter: {
              and: [
                filter,
                {
                  or: [
                    itemFilter,
                    {
                      masterItem: itemFilter
                    }
                  ]
                }
              ]
            } as DeepPartial<CatalogItemFilter>,
            first: paging.perPage,
            offset: (paging.page - 1) * paging.perPage
          }
        );

        return {
          ...items,
          results: items.results.map(catalogItemAdapter)
        };
      } catch (e) {
        throw apiErrorHandler('Error fetching catalog items', e);
      }
    },
    {
      enabled: !!companyId && !!paging && !!(catalogId || catalogType),
      initialData: {
        results: [],
        total: 0
      },
      keepPreviousData: true
    }
  );
};

export const useCatalogItemsMutation = () => {
  const { showError, showSuccess } = useToast();
  const { companyId } = useRoutes();
  const queryClient = useQueryClient();

  const invalidateQueries = () =>
    Promise.all([ReactQueryKey.CatalogItem, ReactQueryKey.Catalog].map((key) => queryClient.invalidateQueries([key])));

  const update = useMutation<void, Error, { id: number; dto: CatalogItemUpdateDto }>(
    async ({ id, dto }) => {
      try {
        await api.update(companyId, id, dto);
      } catch (error) {
        throw errorHandler(error);
      }
    },
    {
      onError: (error) => {
        showError(error.message);
      },
      onSuccess: async () => {
        showSuccess('Successfully updated.');
        await invalidateQueries();
      }
    }
  );

  const remove = useMutation<void, Error, Pick<CatalogItemFromApi, 'id'>>(
    async ({ id }) => {
      try {
        await api.update(companyId, id, {
          isArchived: true
        });
      } catch (error) {
        throw errorHandler(error);
      }
    },
    {
      onError: (error) => {
        showError(error.message);
      },
      onSuccess: async () => {
        showSuccess('Successfully removed.');
        await invalidateQueries();
      }
    }
  );

  const create = useMutation<CatalogItem, Error, { dto: CatalogItemCreateDto }>(
    async ({ dto }) => {
      try {
        return (await api.create(companyId, dto)).data as unknown as CatalogItem;
      } catch (error) {
        throw errorHandler(error);
      }
    },
    {
      onError: (error) => {
        showError(error.message);
      },
      onSuccess: async () => {
        showSuccess('Successfully created.');
        await invalidateQueries();
      }
    }
  );

  const createFromMaster = useMutation<CatalogItem, Error, { dto: CatalogItemFromMasterDto }>(
    async ({ dto }) => {
      try {
        return (await api.createFromMaster(companyId, dto)).data as unknown as CatalogItem;
      } catch (error) {
        throw errorHandler(error);
      }
    },
    {
      onError: (error) => {
        showError(error.message);
      },
      onSuccess: async () => {
        showSuccess('Successfully created.');
        await invalidateQueries();
      }
    }
  );

  return {
    update,
    create,
    remove,
    createFromMaster
  };
};
