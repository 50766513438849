import { ReactQueryKey } from '@enums';
import { File, Project } from '@generated/types/graphql';
import { postGraphql } from '@services/api/base/graphql';
import { apiErrorHandler } from '@utils/api';
import { gql } from 'graphql-request';
import { useQuery } from 'react-query';

export type RecordDocListItem = Pick<
  File,
  | 'id'
  | 'name'
  | 'stageId'
  | 'metaData'
  | 'downloadUrl'
  | 'type'
  | 'isArchived'
  | 'projectId'
  | 'privilege'
  | 'updatedAt'
  | 'form'
  | 'createdByUser'
  | 'annotations'
  | 'commentsByFileIdConnection'
  | 'sharedWithPortal'
  | 'createdByContact'
  | 'projectColumnValueFileProjectColumnValue'
  | 'valueFile'
>;

export const useRecordDocList = (recordId: number, isArchivedShown = false) => {
  return useQuery<RecordDocListItem[]>([ReactQueryKey.RecordDocList, recordId, isArchivedShown], async () => {
    try {
      const { files, project } = await postGraphql<{ files: File[]; project: Project }>(
        gql`
          query RECORD_DOC_LIST_QUERY($recordId: Int!, $isArchivedShown: Boolean!) {
            files(
              filter: {
                projectId: { equalTo: $recordId }
                isArchived: { in: [false, $isArchivedShown] }
                privilege: { notEqualTo: "none" }
              }
            ) {
              id
              name
              stageId
              projectId
              metaData
              downloadUrl
              type
              isArchived
              updatedAt
              privilege
              sharedWithPortal
              form {
                id
              }
              annotations

              createdByUser {
                id
                firstName
                lastName
                avatarUrl
              }

              createdByContact {
                id
                name
                portalStatus
              }

              commentsByFileIdConnection {
                totalCount
              }

              projectColumnValueFileProjectColumnValue {
                # file is coming from record property
                projectColumnValue {
                  column {
                    name
                  }
                }
              }

              valueFile {
                # file from form
                name
              }
            }

            project(id: $recordId) {
              projectPropertiesValues(filter: { fileValuesIndirectExist: true }) {
                files {
                  id
                  name
                  stageId
                  projectId
                  metaData
                  downloadUrl
                  type
                  isArchived
                  updatedAt
                  privilege
                  sharedWithPortal
                  form {
                    id
                  }
                  annotations

                  createdByUser {
                    id
                    firstName
                    lastName
                    avatarUrl
                  }

                  createdByContact {
                    id
                    name
                    portalStatus
                  }

                  commentsByFileIdConnection {
                    totalCount
                  }

                  projectColumnValueFileProjectColumnValue {
                    # file is coming from record property
                    projectColumnValue {
                      column {
                        name
                      }
                    }
                  }

                  valueFile {
                    # file from form
                    name
                  }
                }
              }
            }
          }
        `,
        { recordId, isArchivedShown }
      );

      const projectFiles = project.projectPropertiesValues.flatMap((value) => value?.files ?? []);

      return [...files, ...projectFiles.filter((file) => !files.some((f) => f.id === file.id))];
    } catch (e) {
      throw apiErrorHandler('Error fetching record doc list', e);
    }
  });
};
