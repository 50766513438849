import { useQuery, useQueryClient, useMutation } from 'react-query';
import { ReactQueryKey } from '@enums';
import { useRoutes } from '@hooks/useRoutes';
import { CatalogType, LineItemDto } from '@types';
import { LineItem, Project } from '@generated/types/graphql';
import { postGraphql } from '@services/api/base/graphql';
import { gql } from 'graphql-request';
import { useToast } from '@hooks/useToast';
import api from '@services/api/projectApi';
import { errorHandler } from '@services/api/helpers';
import { apiErrorHandler, notNumberToZero } from '@utils';
import { catalogItemAdapter } from '@hooks/useCatalog';
import { cloneDeep } from 'lodash';

type LineItemAggregate = {
  cost: number;
  price: number;
  profit: number;
  margin: number;
};

export type LineItemResult = {
  [key in CatalogType]: { items: LineItem[] } & LineItemAggregate & { ofTotalPrice: number; ofTotalCost: number };
} & LineItemAggregate;

export const useLineItem = (projectId: number) => {
  const getDefaultState = () =>
    cloneDeep(
      Object.values(CatalogType).reduce(
        (acc, type) => ({
          ...acc,
          [type]: {
            items: [],
            cost: 0,
            price: 0,
            margin: 0,
            profit: 0
          }
        }),
        {
          cost: 0,
          price: 0,
          margin: 0,
          profit: 0
        }
      ) as LineItemResult
    );

  const calculateAggregates = (items: LineItem[]) => {
    const catalogTypes = Object.values(CatalogType);
    const lineItems = items.reduce(
      (acc, item) => {
        acc[item.catalogItem.type as CatalogType].items.push({
          ...item,
          catalogItem: catalogItemAdapter(item.catalogItem)
        });
        acc[item.catalogItem.type as CatalogType].cost += item.totalCost;
        acc[item.catalogItem.type as CatalogType].price += item.totalPrice;

        return acc;
      },
      getDefaultState() as Pick<LineItemResult, CatalogType>
    );

    catalogTypes.forEach((type) => {
      lineItems[type].profit = lineItems[type].price - lineItems[type].cost;
      lineItems[type].margin = +notNumberToZero(
        ((lineItems[type].price - lineItems[type].cost) / lineItems[type].price) * 100
      ).toFixed(2);
    });

    const result = {
      ...lineItems,
      cost: catalogTypes.reduce((acc, type) => acc + lineItems[type].cost, 0),
      price: catalogTypes.reduce((acc, type) => acc + lineItems[type].price, 0)
    };

    catalogTypes.forEach((type) => {
      lineItems[type].ofTotalPrice = +notNumberToZero((lineItems[type].price / result.price) * 100).toFixed(2);
      lineItems[type].ofTotalCost = +notNumberToZero((lineItems[type].cost / result.cost) * 100).toFixed(2);
    });

    return {
      ...result,
      profit: result.price - result.cost,
      margin: +notNumberToZero(((result.price - result.cost) / result.price) * 100).toFixed(2)
    };
  };

  const fetch = useQuery<LineItemResult>(
    [ReactQueryKey.LineItem, projectId],
    async () => {
      try {
        return calculateAggregates(
          (
            await postGraphql<{ project: Project }>(
              gql`
                query PROJECT_LINE_ITEMS_QUERY($projectId: Int!) {
                  project: project(id: $projectId) {
                    lineItemsByProjectId {
                      id
                      quantity
                      description
                      unitCost
                      totalCost
                      totalPrice
                      unitPrice
                      createdAt
                      createdByUser {
                        firstName
                        lastName
                      }
                      mainUserByUpdatedBy {
                        firstName
                        lastName
                      }
                      catalogItem {
                        id
                        name
                        manufacturer
                        code
                        sku
                        type
                        datasheet
                        description
                        image {
                          metaData
                          id
                          name
                        }
                        spec
                        isCustom
                        companyId
                        masterItem {
                          id
                          name
                          code
                          manufacturer
                          sku
                          type
                          datasheet
                          description
                          image {
                            metaData
                            id
                            name
                          }
                          spec
                        }
                      }
                    }
                  }
                }
              `,
              { projectId }
            )
          ).project.lineItemsByProjectId
        );
      } catch (e) {
        throw apiErrorHandler('Error fetching line items', e);
      }
    },
    {
      enabled: !!projectId,
      initialData: getDefaultState()
    }
  );

  return {
    ...fetch,
    calculateAggregates,
    getDefaultState
  };
};

export const useLineItemsMutation = () => {
  const { showError, showSuccess } = useToast();
  const { companyId } = useRoutes();
  const queryClient = useQueryClient();

  const invalidateQueries = () =>
    Promise.all([ReactQueryKey.LineItem].map((key) => queryClient.invalidateQueries([key])));

  const update = useMutation<void, Error, { projectId: number; dto: LineItemDto[] }>(
    async ({ projectId, dto }) => {
      try {
        await api.updateLineItems(projectId, dto, companyId);
      } catch (error) {
        throw errorHandler(error);
      }
    },
    {
      onError: (error) => {
        showError(error.message);
      },
      onSuccess: async () => {
        showSuccess('Successfully saved.');
        await invalidateQueries();
      }
    }
  );

  return {
    update
  };
};
